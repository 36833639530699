import { RSelect } from "../Component";
import { useState } from "react";
// import { convertYearsDropValue } from "../../utils/Functions";
import { useEffect } from "react";
import { convertYearsDropValue } from "../../utils/Functions";

const YearsDropDown = ({
  value,
  isMulti = false,
  isShowAll = false,
  onSelect,
  handleOnSearch,
  placeholder,
  handleOnSelect,
  inputSearchString,
  carerTypeId,
  startYear,
  ...props
}) => {
  const [list, setList] = useState([]);
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    generateArrayOfYears();
  }, []);

  function generateArrayOfYears() {
    var max = new Date().getFullYear();
    var min = startYear ?? 1900;
    var years = [];

    for (var i = max; i >= min; i--) {
      let object = {
        name: i ?? "",
      };
      years.push(convertYearsDropValue(object));
    }
    setList(years);
  }

  //   var years = generateArrayOfYears();

  const handleSearch = (text) => {
    setSearchString(text);
  };
  return (
    <RSelect
      options={list ?? []}
      isMulti={isMulti}
      placeholder={placeholder}
      value={value}
      onInputChange={handleSearch}
      onChange={(e) => {
        if (isShowAll === false) {
          onSelect(e);
        } else {
          e.id === 0 ? onSelect(undefined) : onSelect(e);
        }
      }}
      {...props}
    />
  );
};

export default YearsDropDown;
